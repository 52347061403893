import { getGlobals } from '../../utils/globals.utils';
import { install } from './install-editor-actions';
import { filterPage, filterWidgets } from './install.utils';

export const installFromMetaSiteData = async () => {
  const { metaSiteData, webComponents } = getGlobals();
  const webComponentsPages = filterPage(webComponents);
  const widgets = filterWidgets(webComponents);

  const pageWithMetaSiteData = webComponentsPages.find((page) => {
    return page?.componentId === metaSiteData?.componentId;
  });
  const widgetWithMetaSiteData = widgets.find((widget) => {
    return widget?.componentId === metaSiteData?.componentId;
  });

  const pagesToAdd = pageWithMetaSiteData ? [pageWithMetaSiteData] : [];
  const widgetsToAdd = widgetWithMetaSiteData ? [widgetWithMetaSiteData] : [];

  await install({
    pagesToAdd,
    widgetsToAdd,
  });
};
