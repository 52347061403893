import type { WebComponentData } from '@wix/ambassador-app-service-webapp/types';
import type { PageRef } from '@wix/platform-editor-sdk';
import type { WebComponent } from '@wix/custom-element-sdk';
import { TOKEN } from '../../consts';
import { getGlobals } from '../../utils/globals.utils';
import { checkPageSlug } from '../../utils/slug-check';

export const openWidgetSelectorModal = async () => {
  const { editorSDK, customElementSDK, editorOrigin } = getGlobals();
  const apiBaseUrl = customElementSDK.getApiBaseUrl();
  const metaSiteId = await editorSDK.document.info.getMetaSiteId(TOKEN);
  const editorSDKVersion = editorSDK.info.getSdkVersion(TOKEN);

  editorSDK.editor.openModalPanel(TOKEN, {
    url: `${apiBaseUrl}/_serverless/dynamic-settings-renderer?wix-sdk-version=${
      editorSDKVersion.scriptSrc
    }&openWidgetsModal=true&componentId=not_exists&metaSiteId=${metaSiteId}&origin=${JSON.stringify(
      editorOrigin,
    )}`,
    shouldHideHeader: true,
    width: 680,
    height: 423,
  });
};

export const createNewPage = async ({
  component,
}: {
  component: WebComponent;
}) => {
  const {
    editorSDK,
    appData: { appDefinitionId },
  } = getGlobals();
  const pages = await editorSDK.pages.data.getAll('');
  const {
    showPageInMenu = false,
    tagName,
    slug,
    modalTitle,
  } = component.data as WebComponentData;

  const validPageSlug = checkPageSlug(
    slug as string,
    pages.map((page) => page.pageUriSEO),
  );

  return editorSDK.pages.add(TOKEN, {
    title: modalTitle ?? (tagName as string),
    shouldAddMenuItem: showPageInMenu,
    shouldNavigateToPage: true,
    definition: {
      type: 'Page',
      componentType: 'mobile.core.components.Page',
      data: {
        tpaPageId: `${tagName}${
          component.componentId ? `|${component.componentId}` : ``
        }`,
        managingAppDefId: appDefinitionId,
        pageUriSEO: validPageSlug || tagName,
        metaData: {
          pageId: tagName as string,
        },
      },
    },
  });
};

export const addComponentToPage = async ({
  component,
  isPage,
  pageRef,
}: {
  component: WebComponent;
  isPage: boolean;
  pageRef: PageRef;
}) => {
  const { customElementSDK, metaSiteData } = getGlobals();
  const dataEndpointURL = component.data.gfppSettings?.fetchInitialData;
  const initialAttributes = await customElementSDK.generateInitialAttributes({
    wixSettings: metaSiteData,
    dataEndpointURL,
  });
  const { componentDefinition } = customElementSDK.getCustomElementStructure({
    data: component.data,
    widgetId: component.componentId,
    isPage,
    initialAttributes,
  });

  await customElementSDK.addComponent({
    component,
    componentDefinition,
    pageRef,
    metaSiteData,
  });
};

export async function installPages({
  pagesToAdd,
}: {
  pagesToAdd: WebComponent[];
}) {
  const { editorSDK } = getGlobals();

  for (const component of pagesToAdd) {
    const pageRef = await createNewPage({
      component,
    });

    await addComponentToPage({
      component,
      pageRef,
      isPage: true,
    });
  }

  pagesToAdd.length > 1 &&
    (await editorSDK.pages.navigateTo(TOKEN, {
      pageRef: { id: pagesToAdd[0].componentId as string, type: 'DESKTOP' },
    }));
}

export async function install({
  pagesToAdd = [],
  widgetsToAdd = [],
}: {
  pagesToAdd?: WebComponent[];
  widgetsToAdd?: WebComponent[];
}) {
  const { editorSDK } = getGlobals();

  if (pagesToAdd.length) {
    await installPages({
      pagesToAdd,
    });
  }

  if (widgetsToAdd.length === 1) {
    const pageRef = await editorSDK.pages.getCurrent(TOKEN);
    const component = widgetsToAdd[0];

    await addComponentToPage({
      component,
      pageRef,
      isPage: false,
    });
  } else if (widgetsToAdd.length > 1) {
    openWidgetSelectorModal();
  }
}
