import type {
  FlowAPI,
  FlowEditorSDK,
  IHttpClient,
} from '@wix/yoshi-flow-editor';
import type { Origin } from '@wix/platform-editor-sdk';
import { CustomElementSDK } from '@wix/custom-element-sdk';
import type {
  AppData,
  MetaSiteData,
  WebComponent,
} from '@wix/custom-element-sdk';
import { TOKEN } from '../consts';

let httpClient: IHttpClient;
let editorSDK: FlowEditorSDK;
let appData: AppData;
let instanceId: string;
let metaSiteData: MetaSiteData;
let customElementSDK: CustomElementSDK;
let editorOrigin: Origin;
let webComponents: WebComponent[];

export interface InitGlobalsRequest {
  flowApi: FlowAPI;
  appDefinitionId: string;
  sdk: FlowEditorSDK;
  origin: Origin;
}

export async function initGlobals({
  flowApi,
  appDefinitionId,
  sdk,
  origin,
}: InitGlobalsRequest) {
  const client = flowApi.essentials.httpClient;
  const { instanceId: instId } = await sdk.document.info.getAppInstancePayload(
    TOKEN,
  );
  const data: AppData = await sdk.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );

  const ceSDK = new CustomElementSDK({
    httpClient: client,
    editorSDK: sdk,
    appData: data,
    editorOrigin: origin,
  });

  const msData = await ceSDK.getMetaSiteCacheData();

  httpClient = client;
  editorSDK = sdk;
  appData = data;
  instanceId = instId;
  metaSiteData = msData;
  customElementSDK = ceSDK;
  editorOrigin = origin;
  webComponents =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  return {
    httpClient,
    editorSDK,
    appData,
    instanceId,
    metaSiteData,
    customElementSDK,
    editorOrigin,
    webComponents,
  };
}

export function initViewerGlobals({
  client,
  instance,
}: {
  client: IHttpClient;
  instance: string;
}) {
  httpClient = client;
  instanceId = instance;
}

export function getGlobals() {
  return {
    httpClient,
    editorSDK,
    appData,
    instanceId,
    metaSiteData,
    customElementSDK,
    editorOrigin,
    webComponents,
  };
}

export function setGlobals({
  id,
  ceSDK,
}: {
  id: string;
  ceSDK: CustomElementSDK;
}) {
  instanceId = id;
  customElementSDK = ceSDK;
}
