export const checkPageSlug = (pageSlug: string, pageSlugs: string[]) => {
  const sameSlugsArray: string[] = [];
  const digitsArray: number[] = [];

  pageSlugs.forEach((slug) => {
    if (slug === pageSlug) {
      sameSlugsArray.push(slug);
    }
    const digit = parseInt(slug.split('-')[1], 10);
    if (slug.startsWith(pageSlug) && digit) {
      digitsArray.push(digit);
      sameSlugsArray.push(slug);
    }
  });

  if (sameSlugsArray.length < 1) {
    return pageSlug;
  } else {
    if (!digitsArray.includes(sameSlugsArray.length)) {
      const newSlug = `${pageSlug}-${sameSlugsArray.length}`;
      return newSlug;
    } else {
      const largestNum = digitsArray.sort()[digitsArray.sort().length - 1];
      const newSlug = `${pageSlug}-${largestNum + 1}`;
      return newSlug;
    }
  }
};
