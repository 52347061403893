import { install } from './install-editor-actions';
import {
  filterPage,
  filterWidgets,
  findPagesThatHaveNotBeenInstalledInThePast,
} from './install.utils';
import { getGlobals } from '../../utils/globals.utils';

export const firstInstallFlow = async () => {
  const { webComponents } = getGlobals();
  const webComponentsPages = filterPage(webComponents);
  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages,
  );
  const widgetsToAdd = pagesToAdd.length ? [] : filterWidgets(webComponents);

  await install({
    pagesToAdd,
    widgetsToAdd,
  });
};
