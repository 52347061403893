import type { ComponentRef } from '@wix/yoshi-flow-editor';
import type { WebComponent } from '@wix/custom-element-sdk';
import { TOKEN } from '../../consts';
import { getGlobals } from '../../utils/globals.utils';
import type { DeviceType } from '@wix/platform-editor-sdk';

export const generateURL = (url: string | undefined, instanceId: string) =>
  `${
    url?.indexOf('?') !== -1
      ? `${url}&instanceId=${instanceId}`
      : `${url}?instanceId=${instanceId}`
  }`;

export const getFieldsToUpdate = async ({
  webComponent,
  currentComponentData,
  deviceType,
}: {
  webComponent: WebComponent;
  currentComponentData: {
    initialAttributes: string;
    url: string;
    tagName: string;
  };
  deviceType?: DeviceType;
}) => {
  const { instanceId, customElementSDK } = getGlobals();
  const dataEndpointURL = webComponent.data.gfppSettings?.fetchInitialData;
  const wixSettings = JSON.parse(
    JSON.parse(currentComponentData.initialAttributes).wixsettings || '{}',
  );
  const initialAttributes = await customElementSDK.generateInitialAttributes({
    wixSettings,
    dataEndpointURL,
    deviceType,
  });
  const scriptTag = webComponent.data?.scriptTag;
  const tagName = webComponent.data?.tagName;
  const url = generateURL(scriptTag, instanceId);

  const shouldUpdateUrl =
    !!currentComponentData?.url && currentComponentData?.url !== url;

  const shouldUpdateTagName =
    !!currentComponentData?.tagName &&
    currentComponentData?.tagName !== tagName;

  const fieldsToUpdate = {
    ...(shouldUpdateTagName ? { tagName } : {}),
    ...(shouldUpdateUrl ? { url } : {}),
    initialAttributes,
  };

  return fieldsToUpdate;
};

const getAppWidgetComponent = async ({
  component,
}: {
  component: ComponentRef;
}) => {
  const { editorSDK } = getGlobals();
  const ancestors = await editorSDK.components.getAncestors(TOKEN, {
    componentRef: component,
  });

  const appWidgetComponent = ancestors.find(async (ancestor) => {
    const controllerConnections =
      await editorSDK.controllers.getControllerConnections(TOKEN, {
        controllerRef: ancestor,
      });

    return controllerConnections.some(
      (controllerConnection) =>
        controllerConnection.connection.role === 'webComponent' &&
        controllerConnection.componentRef.id === component.id,
    );
  });

  return appWidgetComponent;
};

export const updateComponentsData = async ({
  components,
  deviceType,
}: {
  components: ComponentRef[];
  deviceType?: DeviceType;
}) => {
  const { editorSDK, webComponents } = getGlobals();
  components.map(async (component) => {
    const appWidgetComponent = await getAppWidgetComponent({
      component,
    });

    if (appWidgetComponent) {
      let data:
        | {
            type: string;
          }
        | undefined;

      try {
        data = await editorSDK.document.controllers.getData(TOKEN, {
          controllerRef: appWidgetComponent,
        });
      } catch (e) {
        // For some reason, the controller that is found is "responsive.components.Section", and it throws an error when trying to getting it's data
      }

      const webComponent = webComponents.find(
        (comp) => comp.componentId === data?.type,
      );

      if (webComponent) {
        const currentComponentData =
          (await editorSDK.document.components.data.get(TOKEN, {
            componentRef: component,
          })) as { initialAttributes: string; url: string; tagName: string };

        const fieldsToUpdate = await getFieldsToUpdate({
          webComponent,
          currentComponentData,
          deviceType,
        });

        Object.keys(fieldsToUpdate).length > 0 &&
          (await editorSDK.document.components.data.update(TOKEN, {
            componentRef: component,
            data: fieldsToUpdate,
          }));
      }
    }
  });
};
